
const routes = [
  {
    path: '/login',
    component: () => import('@/views/login')
  },
  {
    path: '/home',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/search',
    component: () => import('@/views/search/index.vue')
  },
  {
    path: '/Readily',
    component: () => import('@/views/home/sonPage/Readily/index.vue'),
    meta: { requireLogin: true }
  },
  {
    path: '/AddProposal',
    component: () => import('@/views/home/sonPage/AddProposal/index.vue'),
    meta: { requireLogin: true }
  },
  {
    path: '/DemandCreate',
    component: () => import('@/views/home/sonPage/DemandCreate/index.vue'),
    meta: { requireLogin: true }
  },
  {
    path: '/CreateDiff',
    component: () => import('@/views/home/sonPage/CreateDiff/index.vue'),
    meta: { requireLogin: true }
  },
  {
    path: '/ConsultingList',
    component: () => import('@/views/home/sonPage/ConsultingList/index.vue')
  },
  {
    path: '/ConsultingDetail',
    component: () => import('@/views/home/sonPage/ConsultingDetail/index.vue')
  },
  {
    path: '/ConsultingCreate',
    component: () => import('@/views/home/sonPage/ConsultingCreate/index.vue'),
    meta: { requireLogin: true }
  }

]

export default routes
