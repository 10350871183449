import request from '@/utils/request'

const state = {
}

const mutations = {
}

const actions = {
  NewsCategoryGetCategoryList({ state }, query) { // 分类列表
    return new Promise((resolve, reject) => {
      request({
        url: '/NewsCategory/GetCategoryList',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  NewsCategoryGetNewsData({ state }, query) { // 根据栏目Id获取相应列表
    return new Promise((resolve, reject) => {
      request({
        url: '/NewsCategory/GetNewsData',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  NewsCategoryGetVideoData({ state }, query) { // 栏目Id获取对应视频列表
    return new Promise((resolve, reject) => {
      request({
        url: '/NewsCategory/GetVideoData',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  NewsCategoryGetDiffList({ state }, query) { // 困难帮扶政策/困难帮扶动态列表
    return new Promise((resolve, reject) => {
      request({
        url: '/NewsCategory/GetDiffList',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  HomeGetHotNews({ state }, query) { // 热点要闻
    return new Promise((resolve, reject) => {
      request({
        url: '/Home/GetHotNews',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  HomeGetVideoNews({ state }, query) { // 获取党建视频
    return new Promise((resolve, reject) => {
      request({
        url: '/Home/GetVideoNews',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  ServicesGetServiceList({ state }, query) { // 获取公共服务/办事指南列表
    return new Promise((resolve, reject) => {
      request({
        url: '/Services/GetServiceList',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  HomeGetNewsInfo({ state }, query) { // 根据Id获取文章详情
    return new Promise((resolve, reject) => {
      request({
        url: '/Home/GetNewsInfo',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  SecretaryGetFirst({ state }, query) { // 获取第一书记
    return new Promise((resolve, reject) => {
      request({
        url: '/Secretary/GetFirst',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  SecretaryGetFirstNewsList({ state }, query) { // 获取第一书记新闻
    return new Promise((resolve, reject) => {
      request({
        url: '/Secretary/GetFirstNewsList',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  SecretaryGetFirstNewsInfo({ state }, query) { // 根据Id获取书记工作动态信息
    return new Promise((resolve, reject) => {
      request({
        url: '/Secretary/GetFirstNewsInfo',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  SecretaryGetSite({ state }, query) { // 获取网站设置信息
    return new Promise((resolve, reject) => {
      request({
        url: '/Secretary/GetSite',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  PbGetPbDem({ state }, query) { // 支部风采
    return new Promise((resolve, reject) => {
      request({
        url: '/Pb/GetPbDem',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  PbGetPbInfo({ state }, query) { // 查看党组织详情
    return new Promise((resolve, reject) => {
      request({
        url: '/Pb/GetPbInfo',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  }
  // UserGetPbUsers({ state }, data) { // 获取标题+图标
  //   return new Promise((resolve, reject) => {
  //     request({
  //       url: '/Report/GetTypeReport',
  //       method: 'post',
  //       data
  //     }).then(res => {
  //       resolve(res)
  //     })
  //   })
  // },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
