<template>
  <div class="logoDiv" :style="{height:`${height}px`}">
    <img class="logo" :src="$beforeUrl($store.state.app.info.logo)">
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 97
    },
    color: {
      type: String,
      default: 'var(--theme)'
    }
  },

  data() {
    return {

    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
   .logoDiv{
      display: flex;
      justify-content: center;
      align-items: center;
      .logo{
        height: 90%;
        margin-right: 10px;
      }
      .text{
        // text-shadow:#fff 1px 0 0,#fff 0 1px 0,#fff -1px 0 0,#fff 0 -1px 0;
        font-weight: 800px;
      }
    }
</style>
