<template>
  <div
    v-if="!loading"
    id="app"
    :style="{
      '--theme': $store.getters.theme,
    }"
  >
    <component
      :is="navComponent"
      v-show="!noShowRouters.includes($route.path)"
    />
    <changeTheme />
    <router-view />
  </div>
  <div v-else v-loading="loading" class="loadingApp" />
</template>

<script>
import changeTheme from '@/utils/changeTheme.vue'
export default {
  components: {
    changeTheme
  },
  data() {
    return {
      noShowRouters: ['/login', '/404'], // 不显示顶部导航栏的路由path
      loading: true,
      navComponent: (resolve) => require([`@/components/${this.$store.getters.moduleName}/index.vue`], resolve)
    }
  },
  created() {
    const userInfo = window.localStorage.getItem('userInfos')
    if (userInfo && userInfo != 'undefined') {
      this.$store.commit('app/SET_USERINFO', JSON.parse(userInfo))
      this.$store.dispatch('login/AccountGetAccountInfo', { id: this.$store.getters.userInfo.id }).then(res => {
        if (res.code == 0) {
          this.$store.commit('app/CHANGE_USERINFO', res.data)
        }
      })
    }

    this.$store
      .dispatch('app/HomeGetSite', {
        domain: window.location.protocol + '//' + window.location.host
      })
      .then((res) => {
        if (res.code == -2){
            this.$router.push('/404')
        }
        this.loading = false
        this.$store.commit('app/SET_MODULENAME', res.data.pcTempId) // 设置模板
        this.$store.commit('app/SET_COLOR', res.data.pcColor) // 设置主题色
        this.$store.commit('app/SET_USERID', res.data.userId) // 设置userId
        this.$store.commit('app/SET_INFO', res.data) // 存储全部
        document.title = res.data.name
        document.querySelector('link[rel="icon"]').href = this.$beforeUrl(res.data.logo)
      })
  }
}
</script>

<style lang="scss">
.loadingApp {
  width: 100vw;
  height: 100vh;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

// @font-face {
//   font-family: "Microsoft YaHei";
//   src: url("./assets/font/Microsoft YaHei UI Bold.ttf");
// }
#app {
  min-height: 100vh;
  width: 100%;
  min-width: 1300px;
  display: flex;
  flex-direction: column;
}
.click {
  cursor: pointer;
}
.aliCenter {
  display: flex;
  align-items: center;
}

//弹框样式,全局
.el-dialog__wrapper {
  display: flex;
  align-items: center;
  .el-dialog {
    margin-top: unset !important;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    .el-dialog__body {
      flex: 1;
    }
  }
}

.oneLine {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block!important;
  word-break:break-all;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.colCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.it {
  //为空的样式
  span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 52px;
    margin-top: 18px;
  }
  img {
    width: 360px;
    height: 300px;
  }
}

.twoLine{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break:break-all;
}

.redDiv{ //红色的框
  width: 70px;
  height: 24px;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: var(--theme);
  position: relative;
  line-height: 24px;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  &::after{
    content: '';
    display: inline-block;
    background: var(--theme);
    opacity: .1;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
  }
}
.greenDiv{ //绿色的框
  width: 70px;
  height: 24px;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2EBC74;
  position: relative;
  line-height: 24px;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  &::after{
    content: '';
    display: inline-block;
    background: #2EBC74;
    opacity: .1;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
  }
}
.cancelDiv{ //取消的框
  width: 70px;
  height: 24px;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  position: relative;
  line-height: 24px;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  &::after{
    content: '';
    display: inline-block;
    background: #999999;
    opacity: .1;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
  }
}
.waitDiv{ //待审批的框
  width: 70px;
  height: 24px;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FF8610;
  position: relative;
  line-height: 24px;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  &::after{
    content: '';
    display: inline-block;
    background: #FF8610;
    opacity: .1;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
  }
}

.rich{ //解决富文本适配问题
  flex:1;
  word-break: break-all;
  overflow-x: hidden;
  img{
    height: auto!important;
  }
  *{
    max-width:100%;
  }
}

</style>

