
const routes = [
  {
    path: '/personal',
    component: () => import('@/views/personal/index.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/personal/page/index/index.vue'),
        meta: { type: 'setting', title: '个人中心', requireLogin: true }
      },
      {
        path: 'UpdateAccount',
        component: () => import('@/views/personal/page/UpdateAccount/index.vue'),
        meta: { type: 'setting', title: '修改用户资料', requireLogin: true }
      },
      {
        path: 'setting',
        component: () => import('@/views/personal/page/setting/index.vue'),
        meta: { type: 'setting', title: '设置', requireLogin: true }
      },
      {
        path: 'setting/UpdatePwd',
        component: () => import('@/views/personal/page/UpdatePwd/index.vue'),
        meta: { type: 'setting', title: '修改密码', requireLogin: true }
      }
    ]
  }
]

const data = [
  { id: 'Readily', name: '群众随手拍' },
  { id: 'Proposal', name: '留言建议' },
  { id: 'Demand', name: '需求对接' },
  { id: 'DifficultyPeople', name: '困难帮扶' },
  { id: 'Online', name: '在线咨询' },
  { id: 'Dialogue', name: '对话书记' },
  { id: 'Supervise', name: '监督举报' },
  { id: 'Information', name: '信息监管' },
  { id: 'Complain', name: '在线投诉' }
]
data.forEach((item, index) => {
  routes[0].children.push(
    {
      path: item.id,
      component: (resolve) => require([`@/views/personal/page/${item.id}/index.vue`], resolve),
      meta: { type: 'setting', title: item.name, requireLogin: true }
    }
  )
  routes[0].children.push(
    {
      path: `${item.id}/detail`,
      component: (resolve) => require([`@/views/personal/page/${item.id}Detail/index.vue`], resolve),
      meta: { type: 'nav', title: item.name, requireLogin: true, backUrl: `/personal/${item.id}?selected=${index}` }
    }
  )
})

export default routes
