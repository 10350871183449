import router from './router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

NProgress.configure({ showSpinner: false }) // NProgress Configuration
router.beforeEach(async(to, from, next) => {
  NProgress.start()
  let userInfo = window.localStorage.getItem('userInfos')
  if (userInfo && userInfo != 'undefined') {
    userInfo = JSON.parse(userInfo)
  } else {
    userInfo = null
  }

  if (userInfo && Object.keys(userInfo).length > 0) { // 表示已登录
    next()
  } else {
    if (to.meta.requireLogin) { // 表示需要登录
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
