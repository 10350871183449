import Vue from 'vue'
import { getUrlParams } from '@/utils/common.js'
import router from '../router'

Vue.prototype.$setContent = (html) => { // 替换富文本的路径
  if (html) {
    let CHHtml = html.replace(
      /(src=\")(\/.*?)(?=")/gim,
      '$1' + 'https://wximg.aliyinba.com/' + '$2'
    )
    CHHtml = CHHtml.replace(
      /(poster=\")(\/.*?)(?=")/gim,
      '$1' + 'https://wximg.aliyinba.com/' + '$2'
    )
    return CHHtml
  } else {
    return html
  }
}

Vue.prototype.$beforeUrl = url => {
  if (!url) return ''
  if (url.slice(0, 1) != '/') {
    return url
  }
  if (url) {
    return 'https://wximg.aliyinba.com/' + url
  }
} // 路径的前缀

Vue.prototype.$go = function(href) { // 跳转路由
  console.log('href', href)
  if (!href) return
  if (decodeURI(href) == decodeURI(window.location.href) || decodeURI(href) == decodeURI(window.location.href.replace(window.location.origin, ''))) return
  const path = /^\//
  if (path.test(href) || href.indexOf(window.location.hostname) != -1) { // 站内跳转
    const param = getUrlParams(href)
    const pathname = href.split('?')[0].replace(window.location.origin, '')
    router.push({ path: pathname, query: param })
  } else {
    window.location.href = href
  }
}

Vue.prototype.$changeParam = function(newParam, isClear = false) { // 修改当前路由数据,跳转url 仅支持内部跳转 isClear是否删除其余参数
  const href = window.location.href
  let param = getUrlParams(href)
  const pathname = href.split('?')[0].replace(window.location.origin, '')
  if (!isClear) {
    Object.keys(newParam).forEach(key => {
      param[key] = newParam[key]
    })
  } else param = newParam
  this.$router.push({ path: pathname, query: param })
}

Vue.prototype.$getParams = function() { // 获取url中的数据
  return getUrlParams()
}

Vue.prototype.$showNotice = function(func, msg = '确定删除?') { // 提示弹框
  this.$confirm(msg, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    func()
  })
}

Vue.prototype.$key = 'NX2BZ-LQT76-6CNSI-MZZQI-K6VM5-EAF6M' // 腾讯key

