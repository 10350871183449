import request from '@/utils/request'

const state = {
}

const mutations = {
}

const actions = {
  AccountAddAccount({ state }, data) { // 注册账号
    return new Promise((resolve, reject) => {
      request({
        url: '/Account/AddAccount',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  AccountLogin({ state }, data) { // 账号密码登录
    return new Promise((resolve, reject) => {
      request({
        url: '/Account/Login',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  AccountGetAccountInfo({ state }, query) { // 获取用户信息
    return new Promise((resolve, reject) => {
      request({
        url: '/Account/GetAccountInfo',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  AccountUpdateAccount({ state }, data) { // 修改个人资料
    return new Promise((resolve, reject) => {
      request({
        url: '/Account/UpdateAccount',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  AccountUpdatePwd({ state }, data) { // 修改密码
    return new Promise((resolve, reject) => {
      request({
        url: '/Account/UpdatePwd',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  AccountGetUserReport({ state }, query) { // 个人中心统计
    return new Promise((resolve, reject) => {
      request({
        url: '/Account/GetUserReport',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  AccountForgetPwd({ state }, data) { // 忘记密码
    return new Promise((resolve, reject) => {
      request({
        url: '/Account/ForgetPwd',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  AccountBindWx({ state }, data) { // 绑定微信
    return new Promise((resolve, reject) => {
      request({
        url: '/Account/BindWx',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },

  ForgetPwd({ state }, data) { // 忘记密码
    return new Promise((resolve, reject) => {
      request({
        url: '/Account/ForgetPwd',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  SendEmail({ state }, query) { // 注册发验证码
    return new Promise((resolve, reject) => {
      request({
        url: '/Account/SendEmail',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  AccountGetAuthUrl({ state }, data) { // 获取二维码获取授权页地址
    return new Promise((resolve, reject) => {
      request({
        url: '/Account/GetAuthUrl',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  AccountWxLogin({ state }, data) { // 扫码登录
    return new Promise((resolve, reject) => {
      request({
        url: '/Account/WxLogin',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
