// import Cookies from 'js-cookie'
import request from '@/utils/request'
import Vue from 'vue'

const state = {
  userId: '',
  theme: '#c80b0b', // 主色调
  moduleName: 'first', // 第一套模板
  info: {}, // 网址所有信息
  module: '',
  userInfo: {}, // 用户的登录信息
  colList: []
}

const mutations = {
  SET_COLOR: (state, color) => {
    state.theme = color
  },
  SET_MODULENAME: (state, module) => {
    state.module = module
    if (module == '001') {
      state.moduleName = 'first'
    } else if (module == '004') {
      state.moduleName = 'second'
    } else if (module == '005') {
      state.moduleName = 'third'
    }
  },
  SET_USERID: (state, info) => {
    state.userId = info
  },
  SET_INFO: (state, info) => {
    state.info = info
  },
  LOGIN: (state, info) => {
    window.localStorage.setItem('userInfos', JSON.stringify(info))
    state.userInfo = info
    const param = Vue.prototype.$getParams()
    const url = (param && param.redirect) ? param.redirect.replace('%2F', '/') : '/'
    Vue.prototype.$go(url)
  },
  LOGIN_OUT: (state) => {
    window.localStorage.removeItem('userInfos')
    state.userInfo = {}
  },
  SET_USERINFO: (state, info) => {
    window.localStorage.setItem('userInfos', JSON.stringify(info))
    state.userInfo = info
  },
  CHANGE_USERINFO: (state, info) => { // 修改USERINFO
    state.userInfo = { ...state.userInfo, ...info }
    window.localStorage.setItem('userInfos', JSON.stringify(state.userInfo))
  }
}

const actions = {
  HomeGetSite({ state }, data) { // 初始化获取信息
    return new Promise((resolve, reject) => {
      request({
        url: '/Site/GetSite',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  MenuGetMenus({ state }, query) { // 顶部导航
    return new Promise((resolve, reject) => {
      request({
        url: '/Menu/GetMenus',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  SitePmLoginUrl({ state }, query) { // 顶部导航
    return new Promise((resolve, reject) => {
      request({
        url: '/Site/PmLoginUrl',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
