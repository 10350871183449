import request from '@/utils/request'

const state = {
}

const mutations = {
}

const actions = {
  ReadilyGetReadilyList({ state }, query) { // 个人中心-随手拍列表
    return new Promise((resolve, reject) => {
      request({
        url: '/Readily/GetReadilyList',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  ReadilyGetReadilyInfo({ state }, query) { // 个人中心-获取随手拍详情
    return new Promise((resolve, reject) => {
      request({
        url: '/Readily/GetReadilyInfo',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  ReadilyDelReadily({ state }, data) { // 个人中心-删除随手拍
    return new Promise((resolve, reject) => {
      request({
        url: '/Readily/DelReadily',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  ReadilyAddReadily({ state }, data) { // 首页-创建随手拍
    return new Promise((resolve, reject) => {
      request({
        url: '/Readily/AddReadily',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  ReadilyGetReadilyType({ state }, query) { // 随手拍类型-下拉框
    return new Promise((resolve, reject) => {
      request({
        url: '/Readily/GetReadilyType',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  // 留言建议
  ProposalGetProposalList({ state }, query) { // 个人中心-获取留言
    return new Promise((resolve, reject) => {
      request({
        url: '/Proposal/GetProposalList',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  ProposalGetProposalInfo({ state }, query) { // 个人中心-获取留言信息
    return new Promise((resolve, reject) => {
      request({
        url: '/Proposal/GetProposalInfo',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  ProposalDelProposal({ state }, data) { // 个人中心-删除留言
    return new Promise((resolve, reject) => {
      request({
        url: '/Proposal/DelProposal',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  ProposalAddProposal({ state }, data) { // 首页-添加留言建议
    return new Promise((resolve, reject) => {
      request({
        url: '/Proposal/AddProposal',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  // 需求对接
  DemandGetDemandList({ state }, query) { // 获取门户需求对接列表
    return new Promise((resolve, reject) => {
      request({
        url: '/Demand/GetDemandList',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  DemandCreate({ state }, data) { // 创建需求对接
    return new Promise((resolve, reject) => {
      request({
        url: '/Demand/Create',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  DemandGetDemandInfo({ state }, query) { // 获取需求对接详情
    return new Promise((resolve, reject) => {
      request({
        url: '/Demand/GetDemandInfo',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  DemandDelDemand({ state }, data) { // 删除需求对接
    return new Promise((resolve, reject) => {
      request({
        url: '/Demand/DelDemand',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  DemandUnDemand({ state }, data) { // 取消需求对接
    return new Promise((resolve, reject) => {
      request({
        url: '/Demand/UnDemand',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  // 困难帮扶

  DifficultyPeopleGetDiffPersonList({ state }, query) { // 个人中心-获取困难帮扶列表
    return new Promise((resolve, reject) => {
      request({
        url: '/DifficultyPeople/GetDiffPersonList',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  DifficultyPeopleGetDiffPersonInfo({ state }, query) { // 个人中心-获取困难帮扶信息
    return new Promise((resolve, reject) => {
      request({
        url: '/DifficultyPeople/GetDiffPersonInfo',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  DifficultyPeopleDiffPersonDel({ state }, data) { // 个人中心-删除困难帮扶
    return new Promise((resolve, reject) => {
      request({
        url: '/DifficultyPeople/DiffPersonDel',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  DifficultyPeopleCreateDiff({ state }, data) { // 首页-创建困难帮扶
    return new Promise((resolve, reject) => {
      request({
        url: '/DifficultyPeople/CreateDiff',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  DifficultyPeopleUnPerson({ state }, data) { // 取消申请
    return new Promise((resolve, reject) => {
      request({
        url: '/DifficultyPeople/UnPerson',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  }, // 在线咨询，对话书记、监督举报、在线投诉、信息监督

  ConsultingGetUserConsultList({ state }, query) { // 个人中心-获取在线咨询，对话书记、监督举报、在线投诉、信息监督列表
    return new Promise((resolve, reject) => {
      request({
        url: '/Consulting/GetUserConsultList',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  ConsultingConsultingInfo({ state }, query) { // 个人中心-获取在线咨询，对话书记、监督举报、在线投诉、信息监督信息
    return new Promise((resolve, reject) => {
      request({
        url: '/Consulting/ConsultingInfo',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  ConsultingConsultingDel({ state }, data) { // 个人中心-删除在线咨询，对话书记、监督举报、在线投诉、信息监督
    return new Promise((resolve, reject) => {
      request({
        url: '/Consulting/ConsultingDel',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  AccountGetAuthBindUrl({ state }, data) { // 获取授权地址
    return new Promise((resolve, reject) => {
      request({
        url: '/Account/GetAuthBindUrl',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  AccountUnBoundWx({ state }, data) { // 解绑
    return new Promise((resolve, reject) => {
      request({
        url: '/Account/UnBoundWx',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
