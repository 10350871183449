
const routes = [
  {
    path: '/main',
    name: 'main',
    component: () => import('@/views/main/index.vue'),
    children: [
      {
        path: 'detail',
        name: 'detail',
        component: () => import('@/views/main/components/detail/index.vue')
      },
      {
        path: '',
        component: () => import('@/views/main/components/content/index.vue')
      },
      {
        path: '/GetPbDem',
        component: () => import('@/views/main/components/GetPbDem/index.vue')
      },
      {
        path: '/Secretary',
        component: () => import('@/views/main/components/Secretary/index.vue')
      },
      {
        path: '/SecretaryDetail',
        component: () => import('@/views/main/components/SecretaryDetail/index.vue')
      },
      {
        path: '/SecretaryArticleDetail',
        component: () => import('@/views/main/components/SecretaryArticleDetail/index.vue')
      },
      {
        path: '/GetPbDemDetail',
        component: () => import('@/views/main/components/GetPbDemDetail/index.vue')
      }
    ]
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('@/views/map/index.vue')
  }
]

export default routes
